import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { navigate } from '@reach/router';
import { Link } from 'gatsby-plugin-react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { useLocale } from '@app/hooks';

import { Layout, Spinner } from '@app/components';

import image from '@app/images/serdar-bora-bayraktaroglu-about.jpg';

const Contact: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const SCOPE_OPTIONS = {
    scope: 'pages.Contact'
  };
  const { t, language } = useLocale();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const handleFormSubmit = async (data: any) => {
    setIsLoading(true);

    const mutateData = {
      fullName: data.fullName,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
      website: 'serdarborabayraktaroglu'
    };

    const formData = new FormData();
    formData.append('data', JSON.stringify(mutateData));

    if (data.images.length > 0) {
      formData.append('files.images', data.images[0]);
    }

    await axios({
      method: 'POST',
      url: 'https://sbb-panel.herokuapp.com/contact-forms',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }).then(() => {
      navigate(`/${language}/thanks`);
      setIsLoading(false);
    });
  };

  return (
    <Layout>
      <Helmet title={t('labels.pageTitle', SCOPE_OPTIONS)} />
      <section className="relative">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13502.049684139301!2d28.98439767988612!3d41.04762094436737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab70b9d850b73%3A0xc1d628efa22e2c14!2sClinic%20SBB!5e0!3m2!1str!2str!4v1637653380311!5m2!1str!2str"
          width="100%"
          height="720"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        />
        <div className="absolute container mx-auto px-1.5 z-10 top-52 inset-x-0 hidden lg:block">
          <div className="flex flex-col bg-white shadow-xl w-80 bg-opacity-80">
            <img src={image} alt="Serdar Bora Bayraktaroğlu" />
            <div className="p-8">
              <h5 className="text-base font-semibold">{t('labels.clinicSbb', SCOPE_OPTIONS)}</h5>
              <div className="pt-5">
                <p className="text-sm mb-5">{t('labels.title', SCOPE_OPTIONS)}</p>
                <p className="text-sm">{t('labels.subtitle', SCOPE_OPTIONS)}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16">
        <div className="container mx-auto px-1.5">
          <div className="grid lg:grid-cols-4 gap-6 items-start">
            <div className="col-span-3">
              <form onSubmit={handleSubmit(handleFormSubmit)} encType="multipart/form-data">
                <div className="mb-4">
                  <span className="text-gray-500">{t('labels.fullname', SCOPE_OPTIONS)}</span>
                  <input type="text" className="block border p-3.5 w-full shadow-inner focus:outline-none" {...register('fullName', { required: true })} />
                  {errors?.fullName?.type === 'required' && <p className="text-red-600">{t('labels.required', SCOPE_OPTIONS)}</p>}
                </div>
                <div className="mb-4">
                  <span className="text-gray-500">{t('labels.email', SCOPE_OPTIONS)}</span>
                  <input type="email" className="block border p-3.5 w-full shadow-inner focus:outline-none" {...register('email', { required: true })} />
                  {errors?.email?.type === 'required' && <p className="text-red-600">{t('labels.required', SCOPE_OPTIONS)}</p>}
                </div>
                <div className="mb-4">
                  <span className="text-gray-500">{t('labels.phone', SCOPE_OPTIONS)}</span>
                  <input type="text" className="block border p-3.5 w-full shadow-inner focus:outline-none" {...register('phone', { required: true })} />
                  {errors?.phone?.type === 'required' && <p className="text-red-600">{t('labels.required', SCOPE_OPTIONS)}</p>}
                </div>
                <div className="mb-4">
                  <span className="text-gray-500">{t('labels.subject', SCOPE_OPTIONS)}</span>
                  <input type="text" className="block border p-3.5 w-full shadow-inner focus:outline-none" {...register('subject')} />
                </div>
                <div className="mb-4">
                  <span className="text-gray-500">{t('labels.file', SCOPE_OPTIONS)}</span>
                  <input type="file" className="block border p-3.5 w-full shadow-inner focus:outline-none" {...register('images')} />
                </div>
                <div className="mb-4">
                  <span className="text-gray-500">{t('labels.message', SCOPE_OPTIONS)}</span>
                  <textarea className="block border p-3.5 w-full shadow-inner focus:outline-none" rows={10} {...register('message')} />
                </div>
                <p className="my-8">
                  {t('labels.kvvkFirst', SCOPE_OPTIONS)}
                  <Link to="/" className="font-bold">
                    {' '}
                    {t('labels.kvvkLink', SCOPE_OPTIONS)}{' '}
                  </Link>
                  {t('labels.kvvkLast', SCOPE_OPTIONS)}
                </p>
                <button type="submit" className="flex items-center text-center justify-center w-full bg-primary h-12	py-2.5 px-5 rounded text-white font-semibold hover:bg-red-800 cursor-pointer transition-all duration-300" disabled={isLoading}>
                  {t('labels.button', SCOPE_OPTIONS)}
                  {isLoading && <Spinner />}
                </button>
              </form>
            </div>
            <div className="text-gray-600">
              <h3 className="text-xl uppercase mb-2.5">{t('labels.contactInformation', SCOPE_OPTIONS)}</h3>
              <h4 className="text-lg mb-2.5">{t('labels.address', SCOPE_OPTIONS)}</h4>
              <h4 className="text-lg mb-2.5">{t('labels.contactNumber', SCOPE_OPTIONS)}</h4>
              <h4 className="text-lg mb-2.5">{t('labels.contactNumberCorporate', SCOPE_OPTIONS)}</h4>
              <p>
                <a href="mailto:clinicsbb@gmail.com" className="block">
                  clinicsbb@gmail.com
                </a>
                <a href="https://www.serdarborabayraktaroglu.com" className="block">
                  www.serdarborabayraktaroglu.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
